<template>
  <b-container fluid="md" class="enter-number">
    <div>
      <vuexy-logo style="width: 100px; height: 100px" class="mx-4 mt-4" />
    </div>
    <h1 class="mt-1 fs-big">الرجاء إدخال رقم الهاتف</h1>
    <div class="d-flex flex-column justify-content-center align-items-center mt-5">
      <div>
        <b-row style="width: 666px" dir="ltr">
          <b-col cols="12" dir="rtl">
            <div class="number-print d-flex justify-content-between px-1">
              <div class="fs-big-number text-center w-100">{{ number }}</div>
              <div class="fs-big-number d-flex">
                <div
                  class="my-1 mx-1"
                  style="width: 2px; height: 80%; background-color: #d0a153"
                ></div>
                +963
              </div>
            </div>
          </b-col>
          <b-col cols="12">
            <b-button
              class="
                ok-btn
                d-flex
                justify-content-center
                align-items-center
                mt-1
                mb-2
                w-100
              "
              variant="primary"
              :disabled="loading"
              @click="evaluationStatus({status:0,phone_number:number}).then(()=>{
                $router.push({name:'evaluation'})
              })"
            >
               <b-spinner class="align-middle mx-1" v-if="loading" large />
              <div class="number-style">موافق</div>
            </b-button></b-col
          >
          <b-col
            cols="4"
            v-for="n in 10"
            :key="n"
            class="mb-1"
            @click="number.length != 10 ? (number = number + n) : ''"
            ><b-button
              class="number-btn number-style w-100"
              variant="primary"
              v-if="n != 10"
            >
              {{ n }}
            </b-button>
          </b-col>

          <b-col cols="12">
            <div class="d-flex justify-content-between">
              <b-button
                class="
                  ok-btn
                  d-flex
                  justify-content-center
                  align-items-center
                  mb-3
                "
                style="width: 420px"
                variant="primary"
                @click="number.length != 10 ? (number = number + 0) : ''"
              >
                <div class="number-style">0</div>
              </b-button>

              <b-button
                @click="number = number.slice(0, -1)"
                class="
                  ok-btn
                  d-flex
                  justify-content-center
                  align-items-center
                  mb-3
                "
                style="width: 200px"
                variant="primary"
              >
                <feather-icon icon="XIcon" size="30" />
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </b-container>
</template>

<script>
import { BContainer, BRow, BCol, BImg, BButton ,BSpinner} from "bootstrap-vue";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BImg,
    VuexyLogo,
    BButton,
    BSpinner
  },
  data: () => {
    return {
      number: "",
    };
  },
  methods: {
    ...mapActions("publicPages/evaluation", ["evaluationStatus"]),
  },
  computed: {
    ...mapGetters("publicPages/evaluation", ["loading"]),
  },
};
</script>
<style lang="scss">
.enter-number {
  .ok-btn {
    height: 78.49px;
    background: #d0a153;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    border-radius: 10px;
  }
  .number-style {
    font-family: "Cairo";
    font-style: normal;
    font-weight: 400;
    font-size: 50px;
    line-height: 94px;
    color: #ffffff;
  }
  .number-btn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 78.49px;
    background: #d0a153;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
    border-radius: 10.9515px;
  }
  .fs-big-number {
    height: 94px;
    font-weight: 400;
    font-size: 50px;
    line-height: 94px;
    /* identical to box height */
    text-align: center;

    color: #000000;
  }
  .fs-big {
    /* ما مدى رضاك عن الخدمات المقدمة في المنارة */
    position: absolute;
    width: 100%;
    height: 75px;
    top: 40px;
    font-weight: 400;
    font-size: 40px;
    line-height: 75px;
    /* identical to box height */
    text-align: center;
    color: #333;
  }
  .number-print {
    height: 100px;
    background: #ededed;
    border-radius: 10px;
  }
}
</style>